<script>
export default {
	props: {
		items: Array,
		hoverSubmenu: [Number, Boolean],
	},
	inject: {
		appbar: {
			default: null,
		},
	},
	filters: {
		lowerCase(value) {
			const exceptions = ['a', 'con', 'de', 'en', 'para', 'por', 'y', 'o', 'ni', 'la']
			return value
				.split(' ')
				.map((w) => (exceptions.includes(w.toLowerCase()) ? w.toLowerCase() : w))
				.join(' ')
		},
	},
	computed: {
		isRound() {
			return this.appbar?.config.type === 'round'
		},
		classes() {
			return this.isRound ? '' : 'rounded'
		},
	},
	methods: {
		getRoute(item) {
			if (!this.$b.d) return ''

			if (!item.selectType || item.selectType == 'category')
				return this.$shop.getShopRoute({ categories: item.category.familyUrlNames })
			if (item.selectType == 'brand') return this.$shop.getShopRoute({ brand: item.brand.urlName })
			if (item.selectType == 'url') return item.url

			return ''
		},
		showChevron(i) {
			if (i.linkType == 'link') return false
			else if (i.selectType == 'brand' || i.selectType == 'url') return i.links?.length
			else return (!i.noAutoFillCategory && i.category?.children.length) || i.links?.length
		},
	},
}
</script>

<template>
	<div class="categories-list primary pa-0 rounded">
		<div v-for="(item, i) of items" :key="item.posId">
			<router-link :to="getRoute(item)" :key="item.posId">
				<v-hover v-slot="{ hover }">
					<div
						class="categories-list__item pa-2 pl-4 d-flex justify-space-between"
						:class="{
							'rounded-bl rounded-br': i === items.length - 1,
							'rounded-tl rounded-tr': i === 0,
							'on-hover': hover || hoverSubmenu === item.posId,
						}"
						@mouseover="$emit('mouseover', item.posId)"
						:input-value="item.posId === hoverSubmenu"
					>
						<div class="categories-list__title d-flex align-center font-weight-bold">
							<div v-if="item.useIcon" style="display: inline-block">
								<v-icon
									v-if="item.iconType === 'default'"
									:color="hover || hoverSubmenu === item.posId ? $vars.errortext : $vars.primarytext"
									class="mr-2"
									>{{ item.icon }}</v-icon
								>
								<Media
									v-if="item.iconType === 'image'"
									class="mr-2"
									:src="item.iconImage"
									width="24px"
									height="24px"
								/>
								<div v-if="item.iconType === 'html'" v-html="item.iconHtml" class="mr-2" />
							</div>
							<div>
								<div v-if="!item.selectType || item.selectType == 'category'">
									{{ item.category.name | lowerCase }}
								</div>
								<div v-else-if="item.selectType == 'brand'">{{ item.brand.name }}</div>
								<div v-else>{{ item.urlText }}</div>
							</div>
						</div>
						<v-icon
							v-if="showChevron(item)"
							size="small"
							:color="hover || hoverSubmenu === item.posId ? $vars.basetext : $vars.primarytext"
							class="categories-list__chevron pr-2"
							:class="{ 'on-hover': hover || hoverSubmenu === item.posId }"
						>
							mdi-chevron-right
						</v-icon>
					</div>
				</v-hover>
			</router-link>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.categories-list {
	height: auto;
	overflow: auto;
	scrollbar-width: none;
	-ms-overflow-style: none;

	&::-webkit-scrollbar {
		display: none;
	}

	&__item {
		min-height: 30px;
		color: var(--primarytext);

		&.on-hover {
			background-color: var(--base);
			color: var(--basetext);
		}
	}

	&__title {
		font-size: 14px;
	}
}

.v-list-item__title {
	color: unset !important;
}
</style>

