<script>
import appbarMixin from '@/v-shop/mixins/appbarMixin'

export default {
	mixins: [appbarMixin],
	data() {
		return {
			scrollY: null,
			forceHover: 1,
			megaMenuShowing: false,
		}
	},
	methods: {
		scrollHandler() {
			this.scrollY = window.scrollY
		},
	},
	computed: {
		hasAnyMegaMenu() {
			if (!this.config.showMegaMenu) return false
			return this.config.megaMenuType === 'horizontal'
				? this.$srv('MegaMenuHorizontal', []).length
				: this.$srv('MegaMenu', []).length
		},
		hasMegaMenuHorizontal() {
			return this.$srv('MegaMenuHorizontal', []).length
		},
		isTransparent() {
			return this.scrollY < 50 && this.$route.name === 'home'
		},
		submenuStyles() {
			if (this.$ssr) return
			let w = window.innerWidth - 400
			return `width: ${w > 1120 ? 1120 : w}px !important`
		},
	},
	mounted() {
		window.addEventListener('scroll', this.scrollHandler)
	},
	beforeDestroy() {
		window.removeEventListener('scroll', this.scrollHandler)
	},
	cssVars: {
		selector: '.appbar',
	},
}
</script>

<template>
	<div v-if="$b.td" class="appbar transition w100">
		<div :class="$bem('appbar-transparent-container-desktop')">
			<div
				class="transition d-flex align-center w100 h100 px-3"
				:style="`height: ${isTransparent ? 90 : 60}px`"
			>
				<SafeLink to="/" v-if="!showMobileSearch" :class="!hasAnyMegaMenu && 'mr-4'">
					<Media class="appbar--transparent__logo" :src="config.img" />
					<Media class="appbar--transparent__logo-alt" :src="config.imgAlt || config.img" />
				</SafeLink>
				<div>
					<CategoriesMegaMenu
						v-if="showMegaMenu && megaMenuType === 'vertical'"
						:btnText="config.megaMenuBtnText"
						:submenuStyles="submenuStyles"
						noBg
					/>
					<div v-if="config.showMegaMenu && hasMegaMenuHorizontal" style="position: relative">
						<Hover
							v-if="megaMenuType === 'horizontal' && $b.lgAndUp"
							@updateModel="showHorizontalMegaMenu = $event"
							close-delay="250"
							open-delay="250"
						>
							<Button
								:class="{ 'dialog-arrow': showHorizontalMegaMenu || megaMenuShowing }"
								class="base"
								style="background: transparent !important"
								depressed
								large
								tile
							>
								<v-icon
									:class="{ 'icon-rotate': showHorizontalMegaMenu || megaMenuShowing }"
									class="mx-0"
									:color="$vars.primarytext"
									:size="30"
									>mdi-menu</v-icon
								>
								<span
									v-if="config.megaMenuBtnText"
									class="font-1 font-weight-bold"
									style="color: var(--primarytext)"
								>
									{{ config.megaMenuBtnText }}
								</span>
							</Button>
						</Hover>
						<Hover
							v-if="showMegaMenu && megaMenuType === 'horizontal' && $b.lgAndUp"
							@updateModel="megaMenuShowing = $event"
							style="left: 20px; min-width: 1110px"
							unhover-on-route-loading
							v-slot="{ hover }"
							close-delay="250"
						>
							<div v-show="showHorizontalMegaMenu" style="position: absolute; top: 52px">
								<CategoriesMegaMenuHorizontal
									v-model="forceHover"
									class="justify-start pl-3 base rounded rounded-b-0 dialog-arrow elevation-11"
									submenuClass="rounded-t-0 shadow"
									:overlay="false"
									:submenuStyle="{
										left: 0,
										'margin-left': 0,
									}"
									no-calculated-width
									no-overlay
								/>
							</div>
						</Hover>
					</div>
				</div>
				<AppBar-Bottom-MenuItems
					:btnStyle="{
						color: isTransparent ? 'var(--primarytext) !important' : 'var(--basetext) !important',
					}"
					:activatorArrowColor="isTransparent ? $vars.primarytext : $vars.basetext"
					class="d-flex h100"
					style="min-height: 55px"
					noBgItem
				/>
				<div class="w100 d-flex justify-end">
					<AutocompleteSearchField class="searchfield w100" />
					<AppBar-Buttons no-user-btn-text no-wishlist-btn-text no-cart-btn-text />
				</div>
			</div>
		</div>
	</div>

	<div
		v-else
		class="appbar w100"
		:class="$bem('appbar-transparent-container-mobile')"
		style="transition: all 0.4s ease 0s"
	>
		<v-container class="d-flex align-center justify-space-between py-3">
			<v-btn class="mobile-menu-btn" aria-label="menu" @click="menuMobileDrawer = true" icon>
				<v-icon size="30">mdi-menu</v-icon>
			</v-btn>
			<SafeLink to="/" v-if="!showMobileSearch">
				<Media class="appbar--transparent__logo" :src="config.imgMobile" />
				<Media class="appbar--transparent__logo-alt" :src="config.imgMobileAlt || config.imgMobile" />
			</SafeLink>

			<AppBar-Buttons noUserBtnText noWishlistBtnText noCartBtnText />
		</v-container>

		<div class="d-flex justify-center align-center w100 pa-2">
			<div class="w100">
				<AutocompleteSearchField class="searchfield w100" />
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
.appbar-transparent-container-desktop {
	max-width: 1440px;
	width: 100%;
	margin: auto;
}

.transition {
	transition: all 0.4s ease 0s;
}

.transparent-mobile-search ::v-deep form.input-container {
	background: transparent !important;
	border: 1px solid var(--primarytext) !important;
}

.transparent-mobile-search ::v-deep .icon {
	color: var(--primarytext) !important;
}

.transparent-mobile-search ::v-deep input {
	color: var(--primarytext) !important;
	&::placeholder {
		color: var(--primarytext) !important;
	}
}

.searchfield {
	::v-deep input::placeholder {
		color: var(--basetext);
	}
}

::v-deep .input-container {
	background: transparent;
}

::v-deep span .v-badge__badge {
	background-color: var(--cta) !important;
	color: var(--ctatext) !important;
}

.overflow {
	overflow-x: scroll;
}
.w80 {
	width: 40%;
	min-width: 13rem;
}

.fixedHeight {
	height: 40px;
	vertical-align: middle;
}
.search-mobile {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100px;
	z-index: 10;
	transform: translateY(-100%);
}
.search-animation-entry {
	animation: searchMobileEntry 0.3s forwards;
}

.search-animation-leave {
	animation: searchMobileLeave 0.3s forwards;
}

@keyframes searchMobileEntry {
	from {
		transform: translateY(-100%);
	}
	to {
		transform: translateY(0%);
	}
}

@keyframes searchMobileLeave {
	from {
		transform: translateY(0%);
	}
	to {
		transform: translateY(-100%);
	}
}
</style>

