var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.show && _vm.menuItems.length)?_c('div',_vm._l((_vm.menuItems),function(item,index){return _c('div',{key:index,staticClass:"appbar__bottom__menu-items h100"},[(!item.type || item.type === 'link')?_c('v-menu',{staticClass:"px-0",attrs:{"bottom":"","open-on-hover":"","offset-y":"","disabled":!item.children,"min-width":"200px","attach":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('SafeLink',{staticClass:"d-inline-block h100 d-flex",attrs:{"to":item.link}},[_c('v-btn',_vm._g(_vm._b({staticClass:"h100 primary",style:([
							{ background: _vm.noBgItem && 'transparent!important' },
							{ padding: _vm.dense && '0 3px' } ]),attrs:{"text":"","depressed":"","small":""}},'v-btn',attrs,false),on),[(!_vm.hideIcons && item.icon)?_c('v-icon',{staticClass:"mr-2",attrs:{"color":_vm.$vars.primarytext}},[_vm._v(_vm._s(item.icon))]):_vm._e(),_c('span',{staticClass:"font-1 text-wrap-nowrap",class:_vm.dense && 'text-transform-none'},[_vm._v(" "+_vm._s(item.name)+" ")]),(!!item.children)?_c('span',[_c('v-icon',{attrs:{"color":_vm.activatorArrowColor || _vm.$vars.primarytext}},[_vm._v(" mdi-chevron-down ")])],1):_vm._e()],1)],1)]}}],null,true)},[_c('v-list',{attrs:{"color":_vm.$vars.primary}},_vm._l((item.children),function(subitem){return _c('v-list-item',{key:subitem.id},[_c('SafeLink',{staticClass:"w100",attrs:{"to":subitem.link}},[_c('v-list-item-title',[_c('div',[_vm._v(_vm._s(subitem.name))])])],1)],1)}),1)],1):(item.type && item.type == 'imagesCarousel')?_c('div',{staticClass:"d-flex justify-center align-center h100"},[_c('div',{staticClass:"h100",on:{"mouseenter":function () {
						_vm.hoverBtn = true
						_vm.activeIndex = index
					},"mouseleave":function($event){_vm.hoverBtn = false}}},[_c('SafeLink',{staticClass:"h100 d-flex",attrs:{"to":item.link}},[_c('v-btn',{staticClass:"h100 primary",style:([
							{ background: _vm.noBgItem && 'transparent!important' },
							{ padding: _vm.dense && '0 3px' },
							_vm.btnStyle ]),attrs:{"text":"","depressed":"","small":""}},[(!_vm.hideIcons && item.icon)?_c('v-icon',{staticClass:"mr-2",attrs:{"color":_vm.$vars.primarytext}},[_vm._v(_vm._s(item.icon))]):_vm._e(),_c('span',{staticClass:"font-1 text-wrap-nowrap",class:_vm.dense && 'text-transform-none'},[_vm._v(" "+_vm._s(item.name)+" ")]),(!!item.children)?_c('span',[_c('v-icon',{attrs:{"color":_vm.activatorArrowColor || _vm.$vars.primarytext}},[_vm._v(" mdi-chevron-down ")])],1):_vm._e()],1)],1)],1),_c('div',{staticClass:"absolute-wrapper",class:{ 'is-visible': _vm.hoverBtn || _vm.hoverCarousel }},[_c('div',{staticClass:"w100 d-flex justify-center items-center carousel-container",on:{"mouseenter":function($event){_vm.hoverCarousel = true},"mouseleave":function($event){_vm.hoverCarousel = false}}},[_c('div',{staticClass:"w100",staticStyle:{"max-width":"1440px"}},[((_vm.hoverBtn || _vm.hoverCarousel) && _vm.activeIndex == index)?_c('Carousel',{staticClass:"appbar-bottom-carousel",attrs:{"slides-per-page":item.children.length > item.slidesPerPage
									? Number(item.slidesPerPage)
									: item.children.length,"gutter":0,"feather":30,"paginate-by-slide":"","no-drag":"","show-arrows":""}},_vm._l((item.children),function(child,n){return _c('div',{key:n,staticClass:"__slide"},[_c('SafeLink',{attrs:{"to":child.link}},[_c('Media',{staticClass:"__image",attrs:{"src":child.image,"width":"150"}}),_c('h3',{staticClass:"__title"},[_vm._v(_vm._s(child.name))])],1)],1)}),0):_vm._e()],1)])])]):(item.type && item.type === 'allBrands')?_c('v-btn',{staticClass:"h100 primary appbar-menu-items",style:([{ background: _vm.noBgItem && 'transparent!important' }, { padding: _vm.dense && '0 3px' }]),attrs:{"text":"","height":"100%","depressed":"","small":""},on:{"click":function($event){_vm.brandsDialog = true}}},[(!_vm.hideIcons && item.icon)?_c('v-icon',{staticClass:"mr-2",attrs:{"color":_vm.$vars.primarytext}},[_vm._v(_vm._s(item.icon))]):_vm._e(),_c('span',{staticClass:"font-1 text-wrap-nowrap",class:_vm.dense && 'text-transform-none'},[_vm._v(" "+_vm._s(item.name)+" ")])],1):_vm._e()],1)}),0):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }