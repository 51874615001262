<script>
import appbarMixin from '@/v-shop/mixins/appbarMixin'

export default {
	mixins: [appbarMixin],
	data() {
		return {
			menuItems: this.$srv('menuLinks'),
			appBarPosition: this.$srv('AppBarConfig.position', 'center'),
		}
	},
	computed: {
		showHorizontal() {
			return this.showMegaMenu && this.megaMenuType === 'horizontal'
		},
		linksPosition() {
			const positions = {
				left: 'justify-start',
				right: 'justify-end',
				center: 'justify-center',
			}
			return positions[this.appBarPosition]

			// if (this.appBarPosition === 'left') {
			// 	res += ' ml-0'
			// }
			//
			// return res
		},
		megaMenuClass() {
			if (this.appBarPosition !== 'left') return 'mega-menu-absolute'
			return 'left-margin'
		},
	},
	cssVars: {
		selector: '.appbar',
	},
}
</script>

<template>
	<div
		v-if="
			(showMegaMenu && megaMenuType == 'vertical' && megaMenuLocation === 'bottom') ||
			showHorizontal ||
			config.showCategories ||
			(config.showLinks && menuItems && menuItems.length)
		"
		id="appbar-menu"
		class="appbar primary w100 d-flex align-center"
		:class="showHorizontal && linksPosition"
		:style="makeAppbarTransparent && 'background: transparent!important'"
	>
		<CategoriesMegaMenu
			v-if="showMegaMenu && megaMenuType === 'vertical' && megaMenuLocation === 'bottom'"
			closeOnScroll
			:btnText="config.megaMenuBtnText"
			:dense="$b.t"
			:denseWidthScale="0.7"
			:class="megaMenuClass"
			:iconPosition="iconPosition"
		/>
		<div style="min-height: 52px; margin: 0 30px" class="d-flex py-0 w100" :class="[linksPosition]">
			<CategoriesMegaMenuHorizontal
				closeOnScroll
				submenuClass="rounded elevation-12"
				class="justify-center"
				v-if="showHorizontal"
			/>
			<AppBar-Bottom-CategoriesMenu v-if="$srv('AppBarConfig.showCategories', false)" />
			<AppBar-Bottom-MenuItems class="d-flex" />
		</div>
	</div>
</template>

<style scoped lang="scss">
.mega-menu-absolute {
	position: absolute;
	left: 30px;
	height: 100%;
}

.left-margin {
	margin-left: 30px;
}
</style>

