<script>
import { sync, get } from 'vuex-pathify'

export default {
	props: {
		hideIcons: Boolean,
		noBgItem: Boolean,
		dense: Boolean,
		btnClass: [String, Object],
		btnStyle: Object,
		activatorArrowColor: String,
	},
	data() {
		return {
			menuItems: this.$srv('menuLinks'),
			show: this.$srv('AppBarConfig.showLinks'),
			showBrands: false,
			brands: null,
			brandSearch: '',

			hoverBtn: false,
			hoverCarousel: false,
			activeIndex: null,
		}
	},
	computed: {
		brandsDialog: sync('shop/brandsDialog'),
		filteredBrands() {
			let brands = this.brands
			if (this.brandSearch) {
				brands = brands.filter((x) => x.name.toLowerCase().includes(this.brandSearch.toLowerCase()))
			}
			return brands
		},
		height() {
			if (this.$ssr) return
			return window.innerHeight - 64 - 150
		},
		routeLoading: get('shop/routeLoading'),
	},
	watch: {
		showBrands(val) {
			if (val && !this.brands?.length) {
				this.$shopApi.get({
					url: '/catalog/brands',
					query: { buyables: true },
					onSuccess: ({ data }) => {
						this.brands = data.brands
						this.brands.sort((a, b) => {
							const aName = a.name.toLowerCase()
							const bName = b.name.toLowerCase()
							if (aName < bName) return -1
							if (aName > bName) return 1
							return 0
						})
					},
				})
			} else {
				this.brandSearch = ''
			}
		},
		'$route.fullPath'() {
			this.hoverBtn = false
			this.hoverCarousel = false
		},
	},
}
</script>

<template>
	<div v-if="show && menuItems.length">
		<div v-for="(item, index) of menuItems" :key="index" class="appbar__bottom__menu-items h100">
			<v-menu
				v-if="!item.type || item.type === 'link'"
				bottom
				open-on-hover
				offset-y
				:disabled="!item.children"
				class="px-0"
				min-width="200px"
				attach=""
			>
				<v-list :color="$vars.primary">
					<v-list-item class="" v-for="subitem of item.children" :key="subitem.id">
						<SafeLink :to="subitem.link" class="w100">
							<v-list-item-title>
								<div>{{ subitem.name }}</div>
							</v-list-item-title>
						</SafeLink>
					</v-list-item>
				</v-list>
				<template #activator="{ on, attrs }">
					<SafeLink :to="item.link" class="d-inline-block h100 d-flex">
						<v-btn
							v-bind="attrs"
							v-on="on"
							class="h100 primary"
							:style="[
								{ background: noBgItem && 'transparent!important' },
								{ padding: dense && '0 3px' },
							]"
							text
							depressed
							small
						>
							<v-icon v-if="!hideIcons && item.icon" :color="$vars.primarytext" class="mr-2">{{
								item.icon
							}}</v-icon>
							<span class="font-1 text-wrap-nowrap" :class="dense && 'text-transform-none'">
								{{ item.name }}
							</span>
							<span v-if="!!item.children">
								<v-icon :color="activatorArrowColor || $vars.primarytext"> mdi-chevron-down </v-icon>
							</span>
						</v-btn>
					</SafeLink>
				</template>
			</v-menu>
			<div
				v-else-if="item.type && item.type == 'imagesCarousel'"
				class="d-flex justify-center align-center h100"
			>
				<div
					@mouseenter="
						() => {
							hoverBtn = true
							activeIndex = index
						}
					"
					@mouseleave="hoverBtn = false"
					class="h100"
				>
					<SafeLink :to="item.link" class="h100 d-flex">
						<v-btn
							class="h100 primary"
							:style="[
								{ background: noBgItem && 'transparent!important' },
								{ padding: dense && '0 3px' },
								btnStyle,
							]"
							text
							depressed
							small
						>
							<v-icon v-if="!hideIcons && item.icon" :color="$vars.primarytext" class="mr-2">{{
								item.icon
							}}</v-icon>
							<span class="font-1 text-wrap-nowrap" :class="dense && 'text-transform-none'">
								{{ item.name }}
							</span>
							<span v-if="!!item.children">
								<v-icon :color="activatorArrowColor || $vars.primarytext"> mdi-chevron-down </v-icon>
							</span>
						</v-btn>
					</SafeLink>
				</div>
				<div class="absolute-wrapper" :class="{ 'is-visible': hoverBtn || hoverCarousel }">
					<div
						class="w100 d-flex justify-center items-center carousel-container"
						@mouseenter="hoverCarousel = true"
						@mouseleave="hoverCarousel = false"
					>
						<div class="w100" style="max-width: 1440px">
							<Carousel
								v-if="(hoverBtn || hoverCarousel) && activeIndex == index"
								class="appbar-bottom-carousel"
								:slides-per-page="
									item.children.length > item.slidesPerPage
										? Number(item.slidesPerPage)
										: item.children.length
								"
								:gutter="0"
								:feather="30"
								paginate-by-slide
								no-drag
								show-arrows
							>
								<div v-for="(child, n) in item.children" :key="n" class="__slide">
									<SafeLink :to="child.link">
										<Media class="__image" :src="child.image" width="150" />
										<h3 class="__title">{{ child.name }}</h3>
									</SafeLink>
								</div>
							</Carousel>
						</div>
					</div>
				</div>
			</div>
			<v-btn
				@click="brandsDialog = true"
				v-else-if="item.type && item.type === 'allBrands'"
				class="h100 primary appbar-menu-items"
				:style="[{ background: noBgItem && 'transparent!important' }, { padding: dense && '0 3px' }]"
				text
				height="100%"
				depressed
				small
			>
				<v-icon v-if="!hideIcons && item.icon" :color="$vars.primarytext" class="mr-2">{{
					item.icon
				}}</v-icon>
				<span class="font-1 text-wrap-nowrap" :class="dense && 'text-transform-none'">
					{{ item.name }}
				</span>
			</v-btn>
		</div>
	</div>
</template>

<style scoped lang="scss">
.text-wrap-nowrap {
	text-wrap: nowrap;
}
.text-transform-none {
	text-transform: none;
}

.absolute-wrapper {
	background-color: var(--warning);
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 100%;
	width: 100%;
	left: 0;
	opacity: 0;
	pointer-events: none;
	transition: opacity 0.3s ease, transform 0.3s ease;

	&.is-visible {
		opacity: 1;
		transform: translateY(0);
		pointer-events: auto;
	}
}

.appbar-bottom-carousel {
	padding: 24px;

	.__slide {
		.__image {
			margin: 0 auto;
			height: 100%;
		}

		.__title {
			text-align: center;
			color: var(--ctatext);
		}
		&:hover .__title {
			text-decoration: underline;
			color: var(--strongtext);
		}
	}

	// ::v-deep .carousel__arrow-icon {
	// 	color: var(--);
	// }
	// ::v-deep .carousel__arrow--bg {
	// 	background-color: var(--);
	// }
}
</style>

